<template>
  <c-box
    flex-grow="1"
    min-width="0"
  >
    <c-flex
      width="100%"
      align-items="center"
      margin-bottom="20px"
      gap="48px"
    >
      <c-button
        margin-top="17.5px"
        variant="ghost"
        display="flex"
        align-items="center"
        gap="1rem"
        @click="$router.back()"
      >
        <c-image
          w="24px"
          h="24px"
          object-fit="cover"
          :src="require('@/assets/icon-chevron-left.svg')"
        />
        <c-text
          font-size="16px"
          color="primary.400"
          font-weight="500"
        >
          Kembali
        </c-text>
      </c-button>
      
      <BreadcrumbPath

        :paths="[
          {
            label: 'Manajemen Referral',
            href: '/admin/referrals',
          },
          {
            label: 'Manajemen Hadiah',
            isCurrent: true
          },
        ]"
      />
    </c-flex>

    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px 80px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <BaseText
        size-mobile="20px"
        size-desktop="28px"
      >
        {{ isEditPage ? 'Edit Hadiah' : 'Tambah Hadiah' }}
      </BaseText>
      
      <c-box
        as="form"
        margin-top="70px"
        width="100%"
      >
        <BaseInputPhotos
          label="Foto Hadiah"
          is-required
          :photos="images"
          style="margin-top: 16px"
          :is-invalid="isInvalidField($v.images)"
          :invalid-text="parseErrors('Foto Hadiah', $v.images)"
          @update:photos="(newValue) => images = newValue"
          @blur="$v.images.$touch"
        />

        <BaseInputRadio
          v-if="optionCategories.length >= 1"
          v-model="category"
          is-required
          label="Jenis"
          :options="optionCategories"
          :is-invalid="isInvalidField($v.category)"
          :invalid-text="parseErrors('Program', $v.category)"
          @blur="$v.category.$touch"
        />

        <BaseInputRadio
          v-model="multipleRedeem"
          is-required
          label="Batas Penukaran Per Bulan"
          :options="[{
            label: '1 kali',
            value: '0',
          }, {
            label: 'Tidak Terbatas',
            value: '1',
          }]"
          :is-invalid="isInvalidField($v.multipleRedeem)"
          :invalid-text="parseErrors('Program', $v.multipleRedeem)"
          @blur="$v.multipleRedeem.$touch"
        />

        <BaseInputRadio
          v-if="optionDurations.length >= 1 && selectedCategory.label?.toUpperCase() === 'PROGRAM GRATIS'"
          v-model="duration"
          is-required
          label="Durasi Program"
          :options="optionDurations"
          :is-disabled="isFetchingGiftFormProduct"
          :is-invalid="isInvalidField($v.duration)"
          :invalid-text="parseErrors('Durasi Program', $v.duration)"
          @blur="$v.duration.$touch"
        />

        <BaseInputRadio
          v-if="optionServiceGroups.length >= 1 && selectedCategory.label?.toUpperCase() === 'PROGRAM GRATIS'"
          v-model="serviceGroup"
          is-required
          label="Jenis Layanan"
          :options="optionServiceGroups"
          :is-disabled="isFetchingGiftFormProduct"
          :is-invalid="isInvalidField($v.serviceGroup)"
          :invalid-text="parseErrors('Jenis Layanan', $v.serviceGroup)"
          @blur="$v.serviceGroup.$touch"
        />

        <BaseInputCheckbox2
          v-if="selectedCategory.label?.toUpperCase() === 'PROGRAM GRATIS'"
          v-model="giftFormProduct"
          is-required
          label="Opsi Program"
          :options="optionGiftFormProduct"
          :is-disabled="isFetchingGiftFormProduct"
          :is-invalid="isInvalidField($v.giftFormProduct)"
          :invalid-text="parseErrors('Opsi Program', $v.giftFormProduct)"
          template-columns="repeat(4, 1fr)"
          gap="48px"
          @blur="$v.giftFormProduct.$touch"
        />

        <BaseInputText
          v-model="name"
          label="Nama Hadiah"
          placeholder="Masukkan Nama Hadiah"
          full-width
          is-required
          :is-invalid="isInvalidField($v.name)"
          :invalid-text="parseErrors('Nama Hadiah', $v.name)"
          @blur="$v.name.$touch"
        />

        <c-flex gap="32px">
          <BaseInputDate
            v-model="startAt"
            label="Mulai Periode hadiah"
            placeholder="Masukkan Mulai Periode hadiah"
            full-width
            is-required
            :is-invalid="isInvalidField($v.startAt)"
            :invalid-text="parseErrors('Mulai Periode hadiah', $v.startAt)"
            @blur="$v.startAt.$touch"
          >
            <template #input-left-addon>
              <inline-svg
                :src="require('@/assets/icons/icon-calendar.svg')"
                height="24px"
                width="24px"
                fill="#888888"
              />
            </template>
          </BaseInputDate>

          <BaseInputDate
            v-model="endAt"
            label="Akhir Periode hadiah"
            placeholder="Masukkan Akhir Periode hadiah"
            full-width
            is-required
            :is-invalid="isInvalidField($v.endAt)"
            :invalid-text="parseErrors('Akhir Periode hadiah', $v.endAt)"
            @blur="$v.endAt.$touch"
          >
            <template #input-left-addon>
              <inline-svg
                :src="require('@/assets/icons/icon-calendar.svg')"
                height="24px"
                width="24px"
                fill="#888888"
              />
            </template>
          </BaseInputDate>
        </c-flex>

        <BaseInputText
          v-model="price"
          label="Harga Hadiah"
          placeholder="Masukkan Harga Hadiah"
          full-width
          is-required
          :is-invalid="isInvalidField($v.price)"
          :invalid-text="parseErrors('Harga Hadiah', $v.price)"
          @blur="$v.price.$touch"
        />

        <BaseInputText
          v-model="quota"
          label="Kuota Hadiah"
          placeholder="Masukkan Kuota Hadiah"
          full-width
          is-required
          :is-invalid="isInvalidField($v.quota)"
          :invalid-text="parseErrors('Kuota Hadiah', $v.quota)"
          @blur="$v.quota.$touch"
        />

        <BaseInputTextarea
          v-model="description"
          label="Deskripsi Hadiah"
          placeholder="Masukkan Deskripsi Hadiah"
          full-width
          is-required
          :is-invalid="isInvalidField($v.description)"
          :invalid-text="parseErrors('Deskripsi Hadiah', $v.description)"
          @blur="$v.description.$touch"
        />

        <BaseInputTextarea
          v-model="termCondition"
          label="Syarat dan Ketentuan Hadiah"
          placeholder="Masukkan Syarat dan Ketentuan Hadiah"
          full-width
          is-required
          :is-invalid="isInvalidField($v.termCondition)"
          :invalid-text="parseErrors('Syarat dan Ketentuan Hadiah', $v.termCondition)"
          @blur="$v.termCondition.$touch"
        />

        <c-flex
          margin="auto"
          max-width="600px"
          mt="28px"
          justify-content="space-between"
          gap="20px"
        >
          <BaseButton
            size="large"
            border-radius="1000px"
            width="100%"
            variant="outlined"
            :disabled="isSubmitting"
            @click="$router.back()"
          >
            Batal
          </BaseButton>
          <BaseButton
            size="large"
            border-radius="1000px"
            width="100%"
            :disabled="$v.$invalid"
            :is-loading="isSubmitting"
            @click="isOpenModalConfirmationEditGift = true"
          >
            {{ isEditPage ? 'Simpan' : 'Tambah' }}
          </BaseButton>
        </c-flex>
      </c-box>
    </c-box>

    <!-- MODAL KONFIRMASI EDIT HADIAH-->
    <BaseModal
      :is-open="!!isOpenModalConfirmationEditGift"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah kamu yakin ingin {{ isEditPage ? 'merubah' : 'menambahkan' }}  hadiah?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalConfirmationEditGift = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmitting"
              @click="onSubmit"
            >
              Simpan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import { CBox, CFlex, CImage, CText } from '@chakra-ui/vue'
import BaseInputDate from '@/components/elements/base-input-date.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseInputText from '@/components/elements/base-input-text.vue'
import { required, requiredIf, integer, minValue } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import BaseInputTextarea from '@/components/elements/base-input-textarea.vue'
import BaseInputPhotos from '@/components/elements/base-input-photos.vue'
import { isDataEmpty } from '@/utils/is-data-empty'
import BaseInputRadio from '@/components/elements/base-input-radio.vue'
import { reqReferral } from '@/requests/dietela-api/referral'
import imageCompression from 'browser-image-compression'
import BaseInputCheckbox2 from '@/components/elements/base-input-checkbox-2.vue'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import BaseModal from '@/components/elements/base-modal.vue'

export default {
  name: 'ManagementCoachingChallengePage',
  components: {
    CText,
    BaseModal,
    CImage,
    BaseInputCheckbox2,
    BaseInputRadio,
    BaseInputPhotos,
    BaseInputTextarea,
    BaseInputText,
    BaseButton,
    BaseInputDate,
    BaseText,
    BreadcrumbPath,
    CBox,
    CFlex,
  },
  mixins: [generalMixin],
  data() {
    return {
      initData: true,
      isFetchingGiftFormProduct: false,
      isSubmitting: false,
      isOpenModalConfirmationEditGift: false,

      optionCategories: [],
      optionDurations: [],
      optionServiceGroups: [],
      optionGiftFormProduct: [],
      duration: null,
      serviceGroup: null,
      giftFormProduct: [],

      multipleRedeem: '0',
      category: null,
      name: null,
      price: null,
      quota: null,
      description: null,
      termCondition: null,
      startAt: null,
      endAt: null,
      images: [{}],
    }
  },
  computed: {
    selectedCategory() {
      return this.optionCategories.find((v2) => v2.value === this.category)
    },
    triggerWatchOptionGiftFormProduct() {
      return `${this.duration}-${this.serviceGroup}`
    },
    isEditPage() {
      return this.$route.name === 'admin.referrals.edit'
    },
  },
  watch: {
    triggerWatchOptionGiftFormProduct() {
      if (!this.duration && !this.serviceGroup) return
      this.isFetchingGiftFormProduct = true
      reqReferral.giftFormProduct(
        this.$store.getters.axios, {
          duration: this.duration,
          productServiceGroupId: this.serviceGroup,
        }).then((res) => {
        if (this.initData) {
          this.initData = false
        } else {
          this.giftFormProduct = []
        }
        this.isFetchingGiftFormProduct = false
        const data = res?.data?.data
        this.optionGiftFormProduct = data?.map((v) => {
          return {
            label: v?.product?.name,
            value: v?.product?.id,
          }
        })
      })
    },
  },
  async mounted() {
    reqReferral.giftFormFilter(this.$store.getters.axios).then((res) => {
      const option = res?.data?.data
      this.optionCategories = option?.category?.reduce((acc, curr) => {
        if (curr?.isActive === 1) {
          acc.push({
            label: curr?.categoryName,
            value: curr?.id,
          })
        }
        return acc
      }, []) || []
      if (!this.isEditPage) {
        this.category = this?.optionCategories[0]?.value || ''
      }
      this.optionDurations = option?.duration?.map((res) => {
        return {
          label: `${res?.duration} hari`,
          value: res?.duration?.toString(),
        }
      }) || []
      if (!this.isEditPage) {
        this.duration = this?.optionDurations[0]?.value || ''
      }
      this.optionServiceGroups = option?.serviceGroup?.map((res) => {
        return {
          label: res?.productServiceGroup?.productServiceGroupName,
          value: res?.productServiceGroup?.id,
        }
      }) || []
      if (!this.isEditPage) {
        this.serviceGroup = this?.optionServiceGroups[0]?.value || ''
      }
    })

    try {
      if (this.isEditPage) {
        const res = await reqReferral.detailGift(
          this.$store.getters.axios, {
            giftId: this.$route.params?.id,
          })
        const data = res?.data?.data?.data
        this.multipleRedeem = data?.isMultipleRedeem?.toString()
        this.category = data?.category
        this.duration = data?.productServiceFilter?.duration
        this.serviceGroup = data?.productServiceFilter?.productServiceGroupId
        this.giftFormProduct = data?.productServiceFilter?.productIds
        this.name = data?.name
        this.price = data?.price
        this.quota = data?.quota
        this.description = data?.description
        this.termCondition = data?.termCondition
        this.startAt = data?.startAt
        this.endAt = data?.endAt
        this.images = [data?.image]

      }
    } catch (e) {
      this.$toast({
        status: 'error',
        title: 'Error',
        description: parseErrorCatch(e),
        duration: 3000,
      })
    }

  },
  methods: {
    parseErrors,
    isInvalidField,
    async onSubmit() {
      // check form validity
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.isSubmitting = true

      // upload photos
      let arrPromiseImageCompress = []
      let arrPromiseUploadPhotos = []

      for (let photo of this.images || []) {
        if (isDataEmpty(photo)) {
          arrPromiseUploadPhotos.push(photo)
          continue
        }

        if (typeof photo === 'string') {
          arrPromiseUploadPhotos.push(photo)
          continue
        }

        let options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        arrPromiseImageCompress.push(imageCompression(photo.file, options))
      }

      const compressedPhotos = await Promise.all(arrPromiseImageCompress)

      for (let compressedPhoto of compressedPhotos) {
        let formData = new FormData()
        let fileName = compressedPhoto.name
        let file = new File([compressedPhoto], fileName)
        formData.append('file', file, fileName)

        let url = reqReferral.uploadFile(
          this.$store.getters.axios,
          formData,
        ).then((r) => r.data.data?.url)
          .catch((e) => {
            this.$toast({
              status: 'error',
              title: 'Error',
              description: parseErrorCatch(e),
              duration: 3000,
            })
          })
        arrPromiseUploadPhotos.push(url)
      }

      const res = await Promise.all(arrPromiseUploadPhotos)
      const resFilterd = res?.reduce((acc, curr) => {
        if (typeof curr === 'string') {
          acc.push(curr)
        }
        return acc
      }, [])

      arrPromiseImageCompress = []
      arrPromiseUploadPhotos = []

      let type = ''
      if (this.selectedCategory.label?.toUpperCase() === 'UANG TUNAI') {
        type = 'EWALLET'
      } else if (this.selectedCategory.label?.toUpperCase() === 'PROGRAM GRATIS') {
        type = 'PROGRAM'
      }
      let payload = {}
      switch (type) {
        case 'PROGRAM':
          payload = {
            name: this.name,
            price: this.price,
            quota: this.quota,
            description: this.description,
            termCondition: this.termCondition,
            category: this.category,
            image: resFilterd[0],
            startAt: this.startAt,
            endAt: this.endAt,
            isMultipleRedeem: parseInt(this.multipleRedeem),
            //
            duration: this.duration,
            productServiceGroupId: this.serviceGroup,
            productIds: this.giftFormProduct,
          }
          break
        case 'EWALLET':
          payload = {
            name: this.name,
            price: this.price,
            quota: this.quota,
            description: this.description,
            termCondition: this.termCondition,
            category: this.category,
            image: resFilterd[0],
            startAt: this.startAt,
            endAt: this.endAt,
            isMultipleRedeem: parseInt(this.multipleRedeem),
          }
          break
        default:
          throw new Error('type not found!')

      }

      if (this.isEditPage) {
        // edit gift
        reqReferral.editGift(
          this.$store.getters.axios, {
            type,
            giftId: this.$route.params?.id,
            ...payload,
          }).then(() => {
          this.isSubmitting = false
          this.$router.push({ name: 'admin.referrals' })
        }).catch((e) => {
          this.$toast({
            status: 'error',
            title: 'Error',
            description: parseErrorCatch(e),
            duration: 3000,
          })
        })

      } else {
        // create gift
        reqReferral.createGifts(
          this.$store.getters.axios, {
            type,
            ...payload,
          }).then(() => {
          this.$router.push({ name: 'admin.referrals' })
        }).catch((e) => {
          this.$toast({
            status: 'error',
            title: 'Error',
            description: parseErrorCatch(e),
            duration: 3000,
          })
        }).finally(() => {
          this.isSubmitting = false
        })
      }

    },
  },
  metaInfo: {
    title: 'Manajemen Kode AjakSehat | Dietela',
  },
  validations() {
    return {
      multipleRedeem: {
        required,
      },
      category: {
        required,
      },
      name: {
        required,
      },
      price: {
        required,
        integer,
        minValue: minValue(1),
      },
      quota: {
        required,
        integer,
        minValue: minValue(1),
      },
      description: {
        required,
      },
      termCondition: {
        required,
      },
      images: {
        required: (v) => {
          const filtered = v?.reduce((acc, curr) => {
            if (!isDataEmpty(curr)) {
              acc.push(curr)
            }
            return acc
          }, [])
          return !isDataEmpty(filtered)
        },
      },
      startAt: {
        required,
      },
      endAt: {
        required,
      },
      duration: {
        required: requiredIf(() => {
          if (this.selectedCategory.label?.toUpperCase() === 'PROGRAM GRATIS') {
            return true
          }
          return false
        }),
      },
      serviceGroup: {
        required: requiredIf(() => {
          if (this.selectedCategory.label?.toUpperCase() === 'PROGRAM GRATIS') {
            return true
          }
          return false
        }),
      },
      giftFormProduct: {
        required: requiredIf(() => {
          if (this.selectedCategory.label?.toUpperCase() === 'PROGRAM GRATIS') {
            return true
          }
          return false
        }),
      },
    }
  },
}
</script>
