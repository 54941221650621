var render = function () {
  var _vm$selectedCategory$, _vm$selectedCategory$2, _vm$selectedCategory$3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "min-width": "0"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "margin-bottom": "20px",
      "gap": "48px"
    }
  }, [_c('c-button', {
    attrs: {
      "margin-top": "17.5px",
      "variant": "ghost",
      "display": "flex",
      "align-items": "center",
      "gap": "1rem"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "w": "24px",
      "h": "24px",
      "object-fit": "cover",
      "src": require('@/assets/icon-chevron-left.svg')
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Kembali ")])], 1), _c('BreadcrumbPath', {
    attrs: {
      "paths": [{
        label: 'Manajemen Referral',
        href: '/admin/referrals'
      }, {
        label: 'Manajemen Hadiah',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px 80px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditPage ? 'Edit Hadiah' : 'Tambah Hadiah') + " ")]), _c('c-box', {
    attrs: {
      "as": "form",
      "margin-top": "70px",
      "width": "100%"
    }
  }, [_c('BaseInputPhotos', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "label": "Foto Hadiah",
      "is-required": "",
      "photos": _vm.images,
      "is-invalid": _vm.isInvalidField(_vm.$v.images),
      "invalid-text": _vm.parseErrors('Foto Hadiah', _vm.$v.images)
    },
    on: {
      "update:photos": function updatePhotos(newValue) {
        return _vm.images = newValue;
      },
      "blur": _vm.$v.images.$touch
    }
  }), _vm.optionCategories.length >= 1 ? _c('BaseInputRadio', {
    attrs: {
      "is-required": "",
      "label": "Jenis",
      "options": _vm.optionCategories,
      "is-invalid": _vm.isInvalidField(_vm.$v.category),
      "invalid-text": _vm.parseErrors('Program', _vm.$v.category)
    },
    on: {
      "blur": _vm.$v.category.$touch
    },
    model: {
      value: _vm.category,
      callback: function callback($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  }) : _vm._e(), _c('BaseInputRadio', {
    attrs: {
      "is-required": "",
      "label": "Batas Penukaran Per Bulan",
      "options": [{
        label: '1 kali',
        value: '0'
      }, {
        label: 'Tidak Terbatas',
        value: '1'
      }],
      "is-invalid": _vm.isInvalidField(_vm.$v.multipleRedeem),
      "invalid-text": _vm.parseErrors('Program', _vm.$v.multipleRedeem)
    },
    on: {
      "blur": _vm.$v.multipleRedeem.$touch
    },
    model: {
      value: _vm.multipleRedeem,
      callback: function callback($$v) {
        _vm.multipleRedeem = $$v;
      },
      expression: "multipleRedeem"
    }
  }), _vm.optionDurations.length >= 1 && ((_vm$selectedCategory$ = _vm.selectedCategory.label) === null || _vm$selectedCategory$ === void 0 ? void 0 : _vm$selectedCategory$.toUpperCase()) === 'PROGRAM GRATIS' ? _c('BaseInputRadio', {
    attrs: {
      "is-required": "",
      "label": "Durasi Program",
      "options": _vm.optionDurations,
      "is-disabled": _vm.isFetchingGiftFormProduct,
      "is-invalid": _vm.isInvalidField(_vm.$v.duration),
      "invalid-text": _vm.parseErrors('Durasi Program', _vm.$v.duration)
    },
    on: {
      "blur": _vm.$v.duration.$touch
    },
    model: {
      value: _vm.duration,
      callback: function callback($$v) {
        _vm.duration = $$v;
      },
      expression: "duration"
    }
  }) : _vm._e(), _vm.optionServiceGroups.length >= 1 && ((_vm$selectedCategory$2 = _vm.selectedCategory.label) === null || _vm$selectedCategory$2 === void 0 ? void 0 : _vm$selectedCategory$2.toUpperCase()) === 'PROGRAM GRATIS' ? _c('BaseInputRadio', {
    attrs: {
      "is-required": "",
      "label": "Jenis Layanan",
      "options": _vm.optionServiceGroups,
      "is-disabled": _vm.isFetchingGiftFormProduct,
      "is-invalid": _vm.isInvalidField(_vm.$v.serviceGroup),
      "invalid-text": _vm.parseErrors('Jenis Layanan', _vm.$v.serviceGroup)
    },
    on: {
      "blur": _vm.$v.serviceGroup.$touch
    },
    model: {
      value: _vm.serviceGroup,
      callback: function callback($$v) {
        _vm.serviceGroup = $$v;
      },
      expression: "serviceGroup"
    }
  }) : _vm._e(), ((_vm$selectedCategory$3 = _vm.selectedCategory.label) === null || _vm$selectedCategory$3 === void 0 ? void 0 : _vm$selectedCategory$3.toUpperCase()) === 'PROGRAM GRATIS' ? _c('BaseInputCheckbox2', {
    attrs: {
      "is-required": "",
      "label": "Opsi Program",
      "options": _vm.optionGiftFormProduct,
      "is-disabled": _vm.isFetchingGiftFormProduct,
      "is-invalid": _vm.isInvalidField(_vm.$v.giftFormProduct),
      "invalid-text": _vm.parseErrors('Opsi Program', _vm.$v.giftFormProduct),
      "template-columns": "repeat(4, 1fr)",
      "gap": "48px"
    },
    on: {
      "blur": _vm.$v.giftFormProduct.$touch
    },
    model: {
      value: _vm.giftFormProduct,
      callback: function callback($$v) {
        _vm.giftFormProduct = $$v;
      },
      expression: "giftFormProduct"
    }
  }) : _vm._e(), _c('BaseInputText', {
    attrs: {
      "label": "Nama Hadiah",
      "placeholder": "Masukkan Nama Hadiah",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.name),
      "invalid-text": _vm.parseErrors('Nama Hadiah', _vm.$v.name)
    },
    on: {
      "blur": _vm.$v.name.$touch
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('c-flex', {
    attrs: {
      "gap": "32px"
    }
  }, [_c('BaseInputDate', {
    attrs: {
      "label": "Mulai Periode hadiah",
      "placeholder": "Masukkan Mulai Periode hadiah",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.startAt),
      "invalid-text": _vm.parseErrors('Mulai Periode hadiah', _vm.$v.startAt)
    },
    on: {
      "blur": _vm.$v.startAt.$touch
    },
    scopedSlots: _vm._u([{
      key: "input-left-addon",
      fn: function fn() {
        return [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-calendar.svg'),
            "height": "24px",
            "width": "24px",
            "fill": "#888888"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.startAt,
      callback: function callback($$v) {
        _vm.startAt = $$v;
      },
      expression: "startAt"
    }
  }), _c('BaseInputDate', {
    attrs: {
      "label": "Akhir Periode hadiah",
      "placeholder": "Masukkan Akhir Periode hadiah",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.endAt),
      "invalid-text": _vm.parseErrors('Akhir Periode hadiah', _vm.$v.endAt)
    },
    on: {
      "blur": _vm.$v.endAt.$touch
    },
    scopedSlots: _vm._u([{
      key: "input-left-addon",
      fn: function fn() {
        return [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-calendar.svg'),
            "height": "24px",
            "width": "24px",
            "fill": "#888888"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.endAt,
      callback: function callback($$v) {
        _vm.endAt = $$v;
      },
      expression: "endAt"
    }
  })], 1), _c('BaseInputText', {
    attrs: {
      "label": "Harga Hadiah",
      "placeholder": "Masukkan Harga Hadiah",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.price),
      "invalid-text": _vm.parseErrors('Harga Hadiah', _vm.$v.price)
    },
    on: {
      "blur": _vm.$v.price.$touch
    },
    model: {
      value: _vm.price,
      callback: function callback($$v) {
        _vm.price = $$v;
      },
      expression: "price"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Kuota Hadiah",
      "placeholder": "Masukkan Kuota Hadiah",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.quota),
      "invalid-text": _vm.parseErrors('Kuota Hadiah', _vm.$v.quota)
    },
    on: {
      "blur": _vm.$v.quota.$touch
    },
    model: {
      value: _vm.quota,
      callback: function callback($$v) {
        _vm.quota = $$v;
      },
      expression: "quota"
    }
  }), _c('BaseInputTextarea', {
    attrs: {
      "label": "Deskripsi Hadiah",
      "placeholder": "Masukkan Deskripsi Hadiah",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.description),
      "invalid-text": _vm.parseErrors('Deskripsi Hadiah', _vm.$v.description)
    },
    on: {
      "blur": _vm.$v.description.$touch
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }), _c('BaseInputTextarea', {
    attrs: {
      "label": "Syarat dan Ketentuan Hadiah",
      "placeholder": "Masukkan Syarat dan Ketentuan Hadiah",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.termCondition),
      "invalid-text": _vm.parseErrors('Syarat dan Ketentuan Hadiah', _vm.$v.termCondition)
    },
    on: {
      "blur": _vm.$v.termCondition.$touch
    },
    model: {
      value: _vm.termCondition,
      callback: function callback($$v) {
        _vm.termCondition = $$v;
      },
      expression: "termCondition"
    }
  }), _c('c-flex', {
    attrs: {
      "margin": "auto",
      "max-width": "600px",
      "mt": "28px",
      "justify-content": "space-between",
      "gap": "20px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "border-radius": "1000px",
      "width": "100%",
      "variant": "outlined",
      "disabled": _vm.isSubmitting
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" Batal ")]), _c('BaseButton', {
    attrs: {
      "size": "large",
      "border-radius": "1000px",
      "width": "100%",
      "disabled": _vm.$v.$invalid,
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModalConfirmationEditGift = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditPage ? 'Simpan' : 'Tambah') + " ")])], 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalConfirmationEditGift,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah kamu yakin ingin " + _vm._s(_vm.isEditPage ? 'merubah' : 'menambahkan') + " hadiah? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalConfirmationEditGift = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isSubmitting
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }