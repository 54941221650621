var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', _vm._b({
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "margin-bottom": ['16px', '24px']
    }
  }, 'c-form-control', _vm.$attrs, false), [!!_vm.label ? _c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isInvalid ? 'danger.400' : !_vm.isDataEmpty(_vm.photos.filter(function (photo) {
        return !_vm.isDataEmpty(photo);
      })) ? 'primary.400' : '#555',
      "font-weight": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), !_vm.isRequired ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "d": "flex",
      "align-items": "center",
      "overflow-x": "auto",
      "height": ['90px', '160px'],
      "mt": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "d": "flex",
      "gap": "16px",
      "align-items": "center"
    }
  }, [_vm._l(_vm.photos, function (photo, indexPhoto) {
    return _c('c-box', {
      key: indexPhoto,
      attrs: {
        "height": ['60px', '112px'],
        "width": ['60px', '112px']
      }
    }, [photo.blobPreview || !_vm.isDataEmpty(photo) ? _c('c-box', {
      attrs: {
        "position": "relative",
        "d": "inline-block"
      }
    }, [_c('c-image', {
      staticStyle: {
        "object-fit": "cover"
      },
      attrs: {
        "src": photo.blobPreview || photo,
        "height": ['60px', '112px'],
        "width": ['60px', '112px'],
        "rounded": "8px"
      }
    }), _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            background: 'none'
          }
        },
        expression: "{\n              ':hover' : {\n                background: 'none'\n              }\n            }"
      }],
      attrs: {
        "p": "0",
        "m": "0",
        "background-color": "transparent",
        "position": "absolute",
        "top": "-8px",
        "right": "-8px",
        "height": ['20px', '30px'],
        "width": ['20px', '30px']
      },
      on: {
        "click": function click($event) {
          return _vm.handleChangePhoto('delete-photo', indexPhoto, $event);
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-close.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "red"
      }
    })], 1)], 1) : _c('c-button', {
      attrs: {
        "p": "0",
        "h": "100%",
        "w": "100%",
        "as": "label",
        "d": "inline-block",
        "rounded": "8px",
        "overflow": "hidden"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-image-preview.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#008C81"
      }
    }), _c('c-input', {
      attrs: {
        "type": "file",
        "hidden": ""
      },
      on: {
        "change": function change($event) {
          return _vm.handleChangePhoto('input-photo', indexPhoto, $event);
        }
      }
    })], 1)], 1);
  }), false ? _c('BaseButton', {
    attrs: {
      "variant": "outlined",
      "size": "large",
      "border-radius": "1000px",
      "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "#008C81"
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangePhoto('add');
      }
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "padding": "16px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500"
    }
  }, [_vm._v(" Masukkan Gambar ")]), _c('c-text', {
    attrs: {
      "font-size": "14px",
      "font-weight": "400",
      "color": "#888888"
    }
  }, [_vm._v(" (Max 10) ")])], 1)], 1) : _vm._e()], 2)], 1), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.helperText) + " ")]) : _vm._e(), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey
    }, [_vm._v(" ● " + _vm._s(invalidTextValue) + " ")]);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }