import { render, staticRenderFns } from "./base-input-textarea.vue?vue&type=template&id=e7d21860&"
import script from "./base-input-textarea.vue?vue&type=script&lang=js&"
export * from "./base-input-textarea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CFormLabel: require('@chakra-ui/vue').CFormLabel, CTextarea: require('@chakra-ui/vue').CTextarea, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CText: require('@chakra-ui/vue').CText, CFormControl: require('@chakra-ui/vue').CFormControl})
